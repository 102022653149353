import React from 'react'
import { graphql, navigate, withPrefix } from 'gatsby'
import { getUserLangKey } from 'ptz-i18n';
import SEO from '../components/modules/seo/seo';

const IndexPage = ({ data }) => {

  let langKey = "de"
  const { title, languages } = data.site.siteMetadata

  if (typeof window !== 'undefined') {
    const { langs, defaultLangKey } = languages
    langKey = getUserLangKey(langs, defaultLangKey)
    const homeUrl = withPrefix(`/${langKey}`)

    navigate(homeUrl);
  }

  return (
    <div>
      <SEO
        title={title}
        slug="/"
        locale={langKey}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`

export default IndexPage